#chat3 .form-control {
    border-color: transparent;
  }
  
  #chat3 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
  }
  
  .badge-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin-left: 2.9rem;
    margin-top: -0.75rem;
  }

  .contacts-section {
    height: 100%;
    overflow-y: auto;
  }
  
  .messages-section {
    height: 100%;
    overflow-y: auto;
  }

  /* Mobile styles */
@media screen and (max-width: 767px) {
  .small-text-on-mobile {
    font-size: 12px; /* Adjust this value as needed */
  }
}
